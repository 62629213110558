import React from 'react'
import moment from 'moment'

import { rideConfig } from '../../_shared/rides'

import Container from '../../_presentation/container'
import Ride from '../../_presentation/ride'
import markdown from '../../_presentation/markdown'

import './calendar.scss'

const openNearest = items => {
  const diffs = items.map(({ date }) =>
    Math.abs(moment.duration(new moment().diff(new moment(date))).asHours())
  )
  const nearest = diffs.indexOf(Math.min(...diffs))
  return diffs.map((_, i) => i === nearest)
}

export default ({ content, items = [], path }) => {
  let rides = items.filter(
    (rideConfig[path || 'komend'] || rideConfig.komend).filter
  )

  // set defaultOpen indices
  const isOpenList = openNearest(rides)

  return (
    <section id='template-sponsors'>
      {content && (
        <Container width='laptop' vertical>
          {markdown(content, { Tag: 'article', className: 'lead' })}
        </Container>
      )}
      {rides && !!rides.length && (
        <Container width='desktop' vertical tag='ol'>
          {rides.map((ride, key) => {
            return (
              <li key={ride.date + key} className='ride-container'>
                <Ride {...ride} defaultOpen={isOpenList[key]} />
              </li>
            )
          })}
        </Container>
      )}
    </section>
  )
}
