import React from 'react'
import filter from 'lodash/filter'
import { Collapse } from 'reactstrap'

import Datalist from '../../_presentation/datalist'
import markdown from '../../_presentation/markdown'

import './detail.scss'

export default ({
  isOpen,
  toggle,
  isFixed,
  details = [],
  aside,
  image,
  title,
  content,
  ...item
}) => {
  const Tag = toggle ? Collapse : 'section'
  const properties = filter(
    details.map(({ key, unit, transform }) => {
      const value = item[key]
      if (!value) return false
      return { dt: key, dd: ((transform && transform(value)) || value) + unit }
    })
  )
  return (
    <Tag
      isOpen={isOpen}
      className={'detail' + (isFixed ? ' detail__fixed' : '')}
    >
      {!!toggle && (
        <span className='toggle' onClick={toggle}>
          &times;
        </span>
      )}
      <div className='detail-content'>
        {image && (
          <figure>
            <img src={image} alt={title} />
          </figure>
        )}
        <article>
          {markdown(content)}
          {properties && <Datalist items={properties} icons />}
        </article>
        {aside && (
          <aside>
            <Datalist items={aside} jumbo />
          </aside>
        )}
      </div>
    </Tag>
  )
}
