import React, { useContext } from 'react'

import { DataContext } from '../content'
import Error from '../_presentation/errors'

import templates from '../templates'

const Main = ({ template, data }) => {
  const Template = templates[template]
  if (!Template) return <Error error={'404'} />
  return <Template {...data} />
}

export default () => {
  const { template, ...data } = useContext(DataContext) || {}
  return (
    <main id='main'>
      <Main data={data} template={template} />
    </main>
  )
}
