import React from 'react'
import Container from '../container'
import markdown from '../markdown'

import './footer.scss'

export default ({ title, content, sponsors }) => (
  <footer id='footer'>
    {sponsors && !!sponsors.length && (
      <Container width={'desktop'} id='partners' tag='ul'>
        {sponsors.map(({ image, title, major }, key) => {
          return (
            <li key={key} className={major ? 'major ' : ''}>
              <img src={image} alt={title} />
            </li>
          )
        })}
      </Container>
    )}
    <Container width={'tablet'} id='info'>
      <h2>{title}</h2>
      {markdown(content)}
    </Container>
  </footer>
)
