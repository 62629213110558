import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export const Slider = ({ items, index, hide, prev, next }) => (
  <Lightbox
    mainSrc={items[index].content}
    nextSrc={items[(index + 1) % items.length].content}
    prevSrc={
      index
        ? items[(index + items.length - 1) % items.length].content
        : undefined
    }
    onCloseRequest={hide}
    onMovePrevRequest={prev}
    onMoveNextRequest={next}
    imageTitle={items[index].title}
    imageCaption={items[index].intro}
  />
)

export default Slider
