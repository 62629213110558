import Home from './home'
import Text from './text'
import Riders from './riders'
import Sponsors from './sponsors'
import Calendar from './calendar'
import Media from './media'

export default {
  home: Home,
  text: Text,
  calendar: Calendar,
  riders: Riders,
  sponsors: Sponsors,
  media: Media
}
