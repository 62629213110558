import main from './main.json'
import blog from './blog.json'
import calendars from './calendars.json'
import media from './media.json'
import news from './news.json'
import pages from './pages.json'
import riders from './riders.json'
import sponsors from './sponsors.json'

export default {
  main,
  blog,
  calendars,
  media,
  news,
  pages,
  riders,
  sponsors
}
