import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'reactstrap'
import uniq from 'lodash/uniq'

const podium = 3
const ranks = ['trofeo', 'rides']

const List = ({ items, isOpen, type }) => {
  return (
    <Collapse tag='ol' isOpen={isOpen}>
      {items.map((riders, place) => {
        const points = riders[0].calculated[type].total
        return (
          <li key={place} data-place={place + 1}>
            <ul>
              {riders.map(({ firstname, lastname }, key) => (
                <li key={key}>
                  {firstname} {lastname}
                </li>
              ))}
            </ul>
            <small className='ttu'>
              {points} punt{points !== 1 && 'en'}
            </small>
          </li>
        )
      })}
    </Collapse>
  )
}

export default ({ riders, stand }) => {
  const [view, setView] = useState('trofeo')
  const views = {}
  ranks.forEach(r => {
    views[r] = uniq(stand[r])
      .slice(0, podium)
      .map(score =>
        riders.filter(({ calculated }) => calculated[r].total === score)
      )
  })
  return (
    <div id='standings'>
      <h2>
        <span
          className={view === 'trofeo' ? 'active' : ''}
          onClick={() => setView('trofeo')}
        >
          <FontAwesomeIcon icon='trofeo' />
          Trofeo
        </span>{' '}
        |{' '}
        <span
          className={view === 'rides' ? 'active' : ''}
          onClick={() => setView('rides')}
        >
          Ritten
        </span>
      </h2>
      {ranks.map(r => (
        <List key={r} isOpen={view === r} items={views[r]} type={r} />
      ))}
    </div>
  )
}
