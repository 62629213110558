import React from 'react'

import './mediagrid.scss'

export const Picture = ({ content, title, noCaption, ...props }) => {
  return (
    <li {...props}>
      <figure className={'galleryItem'}>
        <img src={content} alt={title} />
        {!noCaption && <figcaption>{title}</figcaption>}
      </figure>
    </li>
  )
}

export const Grid = ({ items = [], toggle = () => 0 }) => {
  return (
    <ul className='mediagrid'>
      {items.map((item, key) => (
        <Picture
          key={key}
          {...item}
          onClick={() => toggle(key)}
          className={'media-item'}
          noCaption
        />
      ))}
    </ul>
  )
}

export default Grid
