import React, { useState } from 'react'

import Container from '../../_presentation/container'
import markdown from '../../_presentation/markdown'

import { Grid } from './Grid'
import { Slider } from './Slider'

export default ({ content, items = [], ...props }) => {
  const [isOpen, setOpen] = useState()
  const [index, setIndex] = useState(0)

  const lightBox = {
    isOpen,
    index,
    show: i => setIndex(i) || setOpen(true),
    hide: () => setOpen(false),
    prev: () => {
      let target = index - 1
      if (target < 0) target = items.length - 1
      setIndex(target)
    },
    next: () => {
      let target = index + 1
      if (target >= items.length) target = 0
      setIndex(target)
    }
  }

  const article = content
    ? [{ ...props, article: markdown(content, { Tag: 'article' }) }]
    : []

  return (
    <Container vertical width='full' id='template-media'>
      {article}
      <Grid items={items} toggle={lightBox.show} />
      {isOpen && <Slider items={items} {...lightBox} />}
    </Container>
  )
}
