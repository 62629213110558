import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './datalist.scss'

export default ({ items, jumbo, icons }) => {
  return (
    <dl
      className={'datalist' + (jumbo ? ' jumbo' : '') + (icons ? ' icons' : '')}
    >
      {items.map((item, key) => {
        const { dt, dd, sup } = item
        if (!dd) return null
        return (
          <span key={key}>
            <dt title={dt}>
              {icons && dt ? <FontAwesomeIcon icon={dt} /> : dt}
            </dt>
            <dd>
              {dd}
              {sup && <sup>{sup}</sup>}
            </dd>
          </span>
        )
      })}
    </dl>
  )
}
