import moment from 'moment'

import { sortNumDesc } from './utils'

export const rideTypes = {
  training: {
    icon: '',
    label: 'Teamtraining',
    rewards: {
      attend: 1
    }
  },
  race: {
    icon: 'medal',
    label: 'Wedstrijd',
    rewards: {
      attend: 2
    }
  },
  trofeo: {
    icon: 'trophy',
    label: 'Trofeo',
    rewards: {
      attend: 3,
      sprint: [3, 2, 1]
    }
  }
}

export const rideConfig = {
  komend: { filter: ({ date }) => moment(date).isAfter() },
  verslagen: { filter: ({ date }) => moment(date).isBefore() },
  uitslagen: { filter: ({ type }) => type === 'race' },
  trofeo: { filter: ({ type }) => type === 'trofeo' }
}

export const ridePoints = ({ points }) => {
  const { training = 0, race = 0, trofeo = {} } = points || {}
  const { rewards } = rideTypes.trofeo
  return {
    rides:
      rideTypes.training.rewards.attend * Number(training || 0) +
      rideTypes.race.rewards.attend * Number(race || 0),
    trofeo:
      Number(trofeo.attend || 0) * rewards.attend +
      (trofeo.sprint || [0, 0, 0]).reduce(
        (v, n, p) => v + Number(n || 0) * (rewards.sprint[p] || 0),
        0
      )
  }
}

export const ridersWithRank = (riders = []) => {
  let riderTotals = []
  const stand = riders.reduce(
    (a, rider, key) => {
      const { rides: r, trofeo: t } = ridePoints(rider)
      riderTotals[key] = { r, t }
      a.rides.push(r)
      a.trofeo.push(t)
      return a
    },
    { rides: [], trofeo: [] }
  )
  stand.rides = stand.rides.sort(sortNumDesc)
  stand.trofeo = stand.trofeo.sort(sortNumDesc)
  return {
    riders: riders.map((rider, key) => ({
      ...rider,
      calculated: {
        rides: {
          rank: stand.rides.indexOf(riderTotals[key].r) + 1,
          total: riderTotals[key].r
        },
        trofeo: {
          rank: stand.trofeo.indexOf(riderTotals[key].t) + 1,
          total: riderTotals[key].t
        }
      }
    })),
    stand
  }
}
