import React, { Fragment, useState } from 'react'
import remove from 'lodash/remove'

import Container from '../../_presentation/container'
import markdown from '../../_presentation/markdown'

import './riders.scss'

import Rider from './Rider'

export default ({ content, items = [] }) => {
  const [detailId, setDetail] = useState([-1, -1])
  const article = markdown(content, { Tag: 'article', className: 'lead' })
  const ranked = []
  const riders = items.map(rider => ({
    ...rider,
    rank: Number(rider.rank || 0) // default to top level
  }))
  while (riders.length) {
    ranked.push(remove(riders, ({ rank }) => rank === ranked.length))
  }
  return (
    <Container vertical width='desktop' id='template-riders'>
      {article}
      {ranked.map((group, rank) => (
        <ol
          key={rank}
          id={'riders-list-' + rank}
          className='riders-list'
          style={{ zIndex: `${ranked.length - rank}` }}
        >
          {group.map((rider, key) => {
            const isOpen = rank === detailId[0] && key === detailId[1]
            return (
              <Fragment key={key}>
                {rider.rank !== rank && (rank = rider.rank) && <hr />}
                <li>
                  <Rider
                    {...rider}
                    isOpen={isOpen}
                    toggle={() =>
                      isOpen ? setDetail([-1, -1]) : setDetail([rank, key])
                    }
                  />
                </li>
              </Fragment>
            )
          })}
        </ol>
      ))}
    </Container>
  )
}
