import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import markdown from '../../_presentation/markdown'

const relatedMap = ({ title, author, date, to }, key) => (
  <li key={key}>
    <Link to={to} title={title}>
      <strong className='text-primary'>{title}</strong>
      <br />
      {author}
      <br />
      {date && (
        <time dateTime={moment(date).format('YYYY-MM-DD')}>
          {moment(date).format('dd D MMMM ‘YY')}
        </time>
      )}
    </Link>
  </li>
)

export default ({ title, author, date, related }) => {
  return (
    <aside>
      {title && <h2>{title}</h2>}
      {(author || date) && (
        <address>
          {author && <div className='strong ttu'>{author}</div>}
          {date && (
            <div className='ttu'>
              <time dateTime={moment(date).format('YYYY-MM-DD')}>
                {moment(date).format('dd D MMMM ‘YY')}
              </time>
            </div>
          )}
        </address>
      )}
      <hr />
      {related && (
        <div id='related'>
          {related.title && <h4>{related.title}</h4>}
          {related.content && markdown(related.content, { Tag: 'div' })}
          {related.items && <ol>{related.items.map(relatedMap)}</ol>}
        </div>
      )}
    </aside>
  )
}
