import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import truncate from 'lodash/truncate'
import moment from 'moment'
import { UncontrolledCarousel } from 'reactstrap'

import Container from '../../_presentation/container'
import markdown from '../../_presentation/markdown'
import Datalist from '../../_presentation/datalist'
import Ride from '../../_presentation/ride'

import Standings from './Standings'

import './home.scss'

export default ({ stats = [], news, calendar, media, ...home }) => {
  return (
    <section id='template-home'>
      {stats && (
        <Container vertical id='stats' data-items={stats.length}>
          <Datalist items={stats} jumbo />
        </Container>
      )}
      {news && (
        <Container id='news-ticker'>
          <strong>{moment(news.date).format('D MMM YYYY')}</strong>
          {markdown(truncate(news.content, { length: 256 }), {
            Tag: 'div',
            className: 'content'
          })}
          <Link to={'/team/nieuws'}>meer nieuws →</Link>
        </Container>
      )}
      {calendar && !!calendar.length && (
        <Container width='width' vertical id='rides'>
          <h2>Ritten</h2>
          <ol>
            <li key={-1} className='more'>
              <Link to='ritten' title='Volledige kalender'>
                <FontAwesomeIcon icon='arrow-right' />
              </Link>
            </li>
            {calendar.map((ride, key) => (
              <li key={key} className='ride-container'>
                <Ride summarized {...ride} />
              </li>
            ))}
          </ol>
        </Container>
      )}
      <Container lean width='no-width' id='standings-container'>
        <Standings {...home} />
        <div id='standings-gallery'>
          {/* <img src='/images/assets/home/rank.jpg' alt='De Ronde 2017' /> */}
          <UncontrolledCarousel
            items={media.map(({ content, title, intro }, key) => ({
              src: content,
              key
            }))}
            indicators={false}
            controls={false}
          />
        </div>
      </Container>
    </section>
  )
}
