import React from 'react'
import PropTypes from 'prop-types'
// import BREAKPOINTS from '../_shared/breakpoints'

import './width.scss'

const Container = ({
  children,
  tag: Tag = 'section',
  width,
  lean,
  force,
  show,
  hide,
  vertical,
  order,
  ...props
}) => {
  const className = ['width']
  if (width) className.push('width__' + width)
  else className.push('no-width')
  if (vertical) className.push('width__vertical')
  if (force) className.push('width__force')
  if (lean) className.push('no-space')
  if (order > 0) className.push('grid-order__' + order)
  if (show) className.push('grid-collapsed__show')
  if (hide) className.push('grid-collapsed__hide')
  if (props.className) className.push(props.className)

  return (
    <Tag {...props} className={className.join(' ')}>
      {children}
    </Tag>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string, // optional tagname
  // width: PropTypes.oneOf(Object.keys(BREAKPOINTS)), // max-width using device keywords
  width: PropTypes.string, // max-width using device keywords
  vertical: PropTypes.bool, // false, apply padding in Y direction
  lean: PropTypes.bool, // false, apply no padding in X direction
  force: PropTypes.bool, // false, always apply padding in X direction
  order: PropTypes.number, // define mobile order when wrapped inside a Grid > Aside/Main
  show: PropTypes.bool, // decide if object is shown or hidden in collapsed Grid state
  hide: PropTypes.bool // decide if object is shown or hidden in collapsed Grid state
}

export default Container
