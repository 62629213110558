import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap'
import { faRoute } from '@fortawesome/free-solid-svg-icons/faRoute'
import { faStream } from '@fortawesome/free-solid-svg-icons/faStream'

import icons from './custom'

library.add(...icons)
library.add(faArrowRight, faInfo, faMap, faRoute, faStream)
