import { ridersWithRank } from '../_shared/rides'

export default ({ route, path, source, target }) => {
  const { riders } = source
  if (!riders) return { ...target, template: 'riders' }
  const data = {
    ...riders,
    items: ridersWithRank(riders.items).riders
  }
  return { ...target, ...data, template: 'riders' }
}
