import React from 'react'
import kebabCase from 'lodash/kebabCase'

import Container from '../../_presentation/container'
import markdown from '../../_presentation/markdown'

import './text.scss'

import Aside from './Aside'

export default ({ content, title, items, ...props }) => {
  const article = content
    ? [{ ...props, article: markdown(content, { Tag: 'article' }) }]
    : []
  const articles = items
    ? items.map(({ content, ...item }, key) => ({
        ...item,
        article: markdown(content, {
          Tag: 'article',
          key: key
        })
      }))
    : []

  return [...article, ...articles].map(({ article, ...aside }, key) => (
    <Container
      key={key}
      vertical
      width='desktop'
      id={kebabCase(aside.title)}
      className='template-text'
    >
      {article}
      <Aside {...aside} />
    </Container>
  ))
}
