import React from 'react'

import Container from '../../_presentation/container'
import Detail from '../../_presentation/detail'
import markdown from '../../_presentation/markdown'

import './sponsors.scss'

export default ({ content, items = [] }) => {
  return (
    <section id='template-sponsors'>
      {content && (
        <Container width='tablet' vertical>
          {markdown(content, { Tag: 'article', className: 'lead' })}
        </Container>
      )}
      {items.map(({ title, content, image, major }) => {
        return (
          <article
            className={'sponsor-item' + (major ? ' sponsor-item__major' : '')}
          >
            <figure>
              <img src={image} alt={title} />
            </figure>
            <Detail content={`#### ${title}\n\n${content}`} isOpen />
          </article>
        )
      })}
    </section>
  )
}
