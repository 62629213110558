const nav = {
  items: [
    {
      path: '',
      label: 'Giro del Mondo'
    },
    {
      path: 'team',
      label: 'Team',
      items: [
        {
          path: '',
          label: 'Doelstellingen'
        },
        {
          path: 'historiek',
          label: 'Historiek'
        },
        {
          path: 'nieuws',
          label: 'Nieuws'
        },
        {
          path: 'sponsors',
          label: 'Sponsors'
        }
      ]
    },
    {
      path: 'ritten',
      label: 'Ritten',
      items: [
        {
          path: '',
          label: 'Komend'
        },
        {
          path: 'verslagen',
          label: 'Voorbij'
        },
        {
          path: 'uitslagen',
          label: 'Wedstrijden'
        },
        {
          path: 'trofeo',
          label: 'Trofeo'
        },
        {
          path: 'afspraken',
          label: 'Afspraken'
        }
      ]
    },
    {
      path: 'atleten',
      label: 'Atleten'
    },
    {
      path: 'blog',
      label: 'Blog'
    },
    {
      path: 'media',
      label: 'Media'
    }
  ]
}

export default ({ data: { main, sponsors } }) => {
  if (!main || !sponsors) return { header: {}, nav, footer: {} }
  const { title, intro, footer = {} } = main
  let stamp
  if (sponsors.items) {
    const majorSponsor = sponsors.items.find(({ major }) => major)
    if (majorSponsor) stamp = majorSponsor.image
  }
  return {
    title,
    intro,
    stamp,
    nav,
    footer: {
      ...footer,
      sponsors: footer.sponsors && sponsors.items
    }
  }
}
