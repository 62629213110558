import React, { createContext } from 'react'
import sortBy from 'lodash/sortBy'

import { ridersWithRank, rideConfig } from '../_shared/rides'

import data from '../data'

import generate from './main'
import blog from './blog'
import riders from './riders'

data.calendars = sortBy(data.calendars, ['label']).reverse()

export const DataContext = createContext(data)

export const getData = ({ match: { params } }) => {
  const { route = '', path = '' } = params
  const generated = generate({ data, params })
  generated.nav.current = [route, path]
  switch (route) {
    case 'team':
      switch (path) {
        case 'historiek':
          return {
            ...generated,
            ...data.pages.find(p => p.path === path),
            template: 'text'
          }
        case 'nieuws':
          return {
            ...generated,
            ...data.news,
            items: sortBy(data.news.items, ['date']).reverse(),
            template: 'text'
          }
        case 'sponsors':
          return { ...generated, ...data.sponsors, template: 'sponsors' }
        default:
          return {
            ...generated,
            ...data.pages.find(p => p.path === route),
            template: 'text'
          }
      }
    case 'ritten':
      switch (path) {
        case '':
          return {
            ...generated,
            ...data.calendars[0],
            items: sortBy(data.calendars[0].items, ['date']),
            template: 'calendar',
            path
          }
        case 'trofeo':
        case 'races':
          return {
            ...generated,
            ...data.calendars[0],
            items: sortBy(data.calendars[0].items, ['date']),
            template: 'calendar',
            path
          }
        case 'verslagen':
        case 'uitslagen':
          return {
            ...generated,
            ...data.calendars[0],
            items: sortBy([...data.calendars[0].items], ['date']).reverse(),
            template: 'calendar',
            path
          }
        case 'afspraken':
          return {
            ...generated,
            ...data.pages.find(p => p.path === 'afspraken'),
            template: 'text'
          }
        default:
          return { ...generated, template: 'calendar' }
      }
    case 'atleten':
      return riders({ route, path, target: generated, source: data })
    case 'blog':
      return blog({ route, path, target: generated, source: data })
    case 'media':
      return { ...generated, ...data.media, template: 'media' }
    default:
      const media =
        data.media.items && data.media.items.filter(({ major }) => !!major)
      const home = {
        logo: true,
        stats: data.main.stats,
        news: sortBy(data.news.items, ['date']).reverse()[0],
        media,
        calendar: sortBy([...data.calendars[0].items], ['date'])
          .filter(rideConfig.komend.filter)
          .slice(0, 3),
        ...ridersWithRank(data.riders.items)
      }
      return { ...generated, ...home, template: 'home' }
  }
}

export default ({ children, ...props }) => {
  return (
    <DataContext.Provider value={getData(props)}>
      {children}
    </DataContext.Provider>
  )
}
