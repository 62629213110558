import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal'
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy'

export const height = {
  prefix: 'fas',
  iconName: 'height',
  icon: [
    8,
    16,
    [],
    'nonzero',
    'M7 0H1a1 1 0 00-1 1v14a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1zM1 15V1h6v2H5.25a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25H7v2H5.25a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25H7v2H5.25a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25H7v2H5.25a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25H7v2H1z'
  ]
}

export const weight = {
  prefix: 'fas',
  iconName: 'weight',
  icon: [
    16,
    16,
    [],
    'nonzero',
    'M15.946 13.933l-2.282-9.129C13.545 4.33 13.15 4 12.7 4H9.99c.316-.419.511-.935.511-1.5a2.5 2.5 0 00-5 0c0 .565.195 1.081.511 1.5h-2.71c-.451 0-.847.33-.965.804l-2.282 9.13C-.206 14.973.512 16 1.5 16h12.998c.99 0 1.708-1.026 1.447-2.067zM6.5 2.5C6.5 1.673 7.173 1 8 1s1.5.673 1.5 1.5S8.827 4 8 4s-1.5-.673-1.5-1.5zm8.352 12.31a.451.451 0 01-.353.19H1.501a.45.45 0 01-.353-.19.742.742 0 01-.124-.634l2.282-9.13c.007-.025.016-.04.014-.046h9.353a.124.124 0 01.02.047l2.283 9.129a.742.742 0 01-.124.634z'
  ]
}

export const birthday = {
  prefix: 'fas',
  iconName: 'birthday',
  icon: [
    14,
    16,
    [],
    'nonzero',
    'M3 3c-.555 0-1-.445-1-1 0-.969 1-.719 1-2 .375 0 1 .922 1 1.75S3.555 3 3 3zm4 0c-.555 0-1-.445-1-1 0-.969 1-.719 1-2 .375 0 1 .922 1 1.75S7.555 3 7 3zm4 0c-.555 0-1-.445-1-1 0-.969 1-.719 1-2 .375 0 1 .922 1 1.75S11.555 3 11 3zm1.5 5h-1V3.5h-1V8h-3V3.5h-1V8h-3V3.5h-1V8h-1A1.5 1.5 0 000 9.5V16h14V9.5A1.5 1.5 0 0012.5 8zm.5 7H1v-2.251c.507-.297.748-.749 1.336-.749.873 0 .977 1 2.336 1C6.01 13 6.147 12 7 12c.88 0 .975 1 2.336 1 1.355 0 1.461-1 2.336-1 .578 0 .82.452 1.328.749V15zm0-3.512c-.3-.246-.663-.488-1.328-.488-1.357 0-1.463 1-2.336 1-.866 0-.983-1-2.336-1-1.339 0-1.476 1-2.328 1-.88 0-.975-1-2.336-1-.67 0-1.034.243-1.336.49V9.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1.988z'
  ]
}

export const address = {
  prefix: 'fas',
  iconName: 'address',
  icon: [
    12,
    16,
    [],
    'nonzero',
    'M6 3C4.346 3 3 4.346 3 6s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-8a6 6 0 00-6 6c0 2.42.843 3.095 5.383 9.677a.75.75 0 001.234 0C11.157 9.095 12 8.42 12 6a6 6 0 00-6-6zm0 14.81C1.647 8.515 1 8.015 1 6c0-1.336.52-2.591 1.464-3.536A4.967 4.967 0 016 1c1.336 0 2.591.52 3.536 1.464A4.967 4.967 0 0111 6c0 2.015-.647 2.515-5 8.81z'
  ]
}

export const elevation = {
  prefix: 'fas',
  iconName: 'elevation',
  icon: [
    27,
    16,
    [],
    'nonzero',
    'M26.489 14.288l-8.085-12.4c-.241-.37-.656-.555-1.07-.555-.415 0-.83.185-1.071.555L14.096 5.21 11.146.605A1.346 1.346 0 0010 0c-.444 0-.888.202-1.147.605L.191 14.132C-.333 14.95.284 16 1.288 16h24.176c.938 0 1.514-.962 1.025-1.712zm-25.084.421L9.96 1.34a.103.103 0 01.04-.007l.024-.009 8.544 13.343-17.163.042zm18.58-.042a1.165 1.165 0 00-.175-.534l-4.925-7.69 2.448-3.755 7.81 11.979h-5.158z'
  ]
}

export const trofeo = { ...faTrophy, iconName: 'trofeo' }

export const race = { ...faMedal, iconName: 'race' }
export const stagerace = { ...faMedal, iconName: 'stagerace' }

export default [
  height,
  weight,
  birthday,
  address,
  elevation,
  trofeo,
  stagerace,
  race,
  faMedal,
  faTrophy
]
