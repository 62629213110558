import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { isNumeric } from '../../_shared/utils'

import Datalist from '../datalist'
import Detail from '../detail'

import './ride.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const typeIcons = {
  race: 'medal',
  stagerace: 'medal',
  trofeo: 'trophy'
}

export default ({
  title,
  content,
  intro,
  stats,
  image,
  date,
  length,
  elevation,
  type,
  address,
  link,
  gpx,
  defaultOpen,
  summarized
}) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  const toggle = () => setOpen(!isOpen)
  const properties = [
    { dt: '', dd: length && length + ' km' },
    {
      dt: 'elevation',
      dd: elevation && elevation + (isNumeric(elevation) ? ' m' : '')
    }
  ]
  const typeIcon = typeIcons[type]
  content = date && moment(date).isAfter() ? intro : content
  return (
    <Fragment>
      <article
        className={'ride ride__' + type + (summarized ? ' ride__summary' : '')}
      >
        <h3>
          {title}
          <small className='ride-type ttu'>
            {typeIcon && (
              <span className='ride-type-icon'>
                <FontAwesomeIcon icon={typeIcon} />
              </span>
            )}
            {type}
          </small>
        </h3>
        <Datalist icons items={properties} />
        <p>
          {date && <strong>{moment(date).format('dd D MMM, HH:mm')}</strong>}
          {date && address && <br />}
          {address && <strong>{address}</strong>}
        </p>
        <ul className='ride-data'>
          {!!(content || stats || image) && (
            <li onClick={toggle}>
              <span className={isOpen ? 'active' : ''}>
                <FontAwesomeIcon icon='info' />
              </span>
            </li>
          )}
          {gpx && (
            <li>
              <a href={gpx} download={gpx.split('/').pop()} title='GPX-bestand'>
                <span className='h6 text-white m-0'>GPX</span>
              </a>
            </li>
          )}
          {link && (
            <li>
              <a
                href={link}
                target='_blank'
                rel='noopener noreferrer'
                title='Kaart'
              >
                <FontAwesomeIcon icon='route' />
              </a>
            </li>
          )}
        </ul>
      </article>
      {!!(content || stats || image) && (
        <Detail
          isOpen={isOpen}
          toggle={toggle}
          {...{ content, aside: stats, image }}
        />
      )}
    </Fragment>
  )
}
