import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import '../_styles/main.scss'

import Content from '../content'

import Head from './Head'
import Nav from './Nav'
import Main from './Main'
import Footer from './Footer'

const Layout = props => (
  <Content {...props}>
    {/* <Seo /> */}
    <Head />
    <Nav />
    <Main />
    <Footer />
  </Content>
)

export default () => (
  <Router>
    <Switch>
      <Route path='/:route?/:path?' component={Layout} />
    </Switch>
  </Router>
)
