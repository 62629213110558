import sortBy from 'lodash/sortBy'
import kebabCase from 'lodash/kebabCase'
import moment from 'moment'

export default ({ route, path, source, target }) => {
  let article, articleIndex
  let items = (source.blog && source.blog.items) || []
  if (items.length) {
    items = sortBy(items, ['date']).reverse()
    items.map(({ path, ...item }, key) => {
      if (!key) return item
      if (path) return { ...item, path }
      item.path = kebabCase(
        new moment(item.data).format('YYYY-MM-DD') + ' ' + item.title
      )
      return item
    })
    articleIndex = path
      ? items.findIndex(({ path: blogPath }) => blogPath === path)
      : 0
    article = items[articleIndex]
  }
  if (article) {
    const { title, intro } = article
    article.header = { ...target.header, title, content: intro }
    article.related = {
      title: 'Meer lezen?',
      items: [
        // items[articleIndex - 2],
        items[articleIndex - 1],
        items[articleIndex + 1]
        // items[articleIndex + 2]
      ]
        .filter(a => a)
        .map(({ title, author, date, ...a }) => ({
          title,
          author,
          date,
          to: '/' + route + (a.path ? '/' + a.path : '')
        }))
    }
  }
  return { ...target, ...article, template: 'text' }
}
