import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './navbar.scss'
import Container from '../container'

export default ({ items = [], current = [] }) => {
  const [navOn, toggleNav] = useState()
  const toggle = () => toggleNav(!navOn)

  let subnav = []
  let level = 0

  const mapper = level => ({ to, path, label, items: subItems = [] }) => {
    if (!level && path === (current[level] || ''))
      subnav = subItems.map(i => ({
        ...i,
        to: i.path ? path + '/' + i.path : path
      }))
    return (
      <li key={path}>
        <Link
          to={'/' + (to || path)}
          className={
            (subItems.length ? 'has-subNav ' : '') +
            (current[level] === path ? 'active ' : '')
          }
        >
          {label}
        </Link>
      </li>
    )
  }

  return (
    <nav id='nav'>
      <FontAwesomeIcon icon='stream' onClick={toggle} className='toggle-nav' />
      <Container
        id='nav-main'
        tag='ul'
        onClick={() => toggleNav(false)}
        className={navOn ? 'nav-on' : ''}
      >
        {items.map(mapper(level++))}
      </Container>
      {!!subnav.length && (
        <Container tag='ol' id='nav-sub'>
          {subnav.map(mapper(level++))}
        </Container>
      )}
    </nav>
  )
}
