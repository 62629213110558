import React from 'react'
import MarkdownIt from 'markdown-it'
import figure from 'markdown-it-implicit-figures'

const md = new MarkdownIt({ typographer: true, breaks: true })

md.use(figure, { figcaption: true })

const render = (content = '', options = {}) => {
  const { Tag = 'div', className = '', ...props } = options
  return (
    <Tag
      {...props}
      className={className
        .split(' ')
        .concat('markdown')
        .join(' ')}
      dangerouslySetInnerHTML={{ __html: md.render(content) }}
    />
  )
}

export { md, render }
