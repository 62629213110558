import React from 'react'
import Container from '../container'

import './header.scss'

const Logo = () => (
  <div id='logo'>
    <h1>Giro del Mondo</h1>
    <small>wielerteam</small>
    <small>gran fondo team</small>
  </div>
)

export default ({ title, intro, logo, stamp }) => (
  <header id='header' className={logo && 'fullscreen'}>
    {logo && <Logo />}
    <Container width={'tablet'} id='intro'>
      <h1>{title}</h1>
      <p className='lead'>{intro}</p>
    </Container>
    {stamp && (
      <figure id='stamp'>
        <img src={stamp} alt={stamp} />
      </figure>
    )}
  </header>
)
