import React from 'react'
import moment from 'moment'

import Detail from '../../_presentation/detail'

const riderDetails = [
  { key: 'height', unit: ' cm' },
  { key: 'weight', unit: ' kg' },
  {
    key: 'birthday',
    unit: '',
    transform: input => moment(input).format('YYYY')
  },
  { key: 'address', unit: '' }
]

export default ({
  isOpen,
  toggle,
  firstname,
  lastname,
  role,
  calculated,
  ...rider
}) => {
  const stand = [
    { dt: 'Rittenstand', dd: calculated.rides['total'], sup: 'ptn' },
    { dt: 'Trofeo', dd: calculated.trofeo['rank'], sup: 'e' }
  ]
  return (
    <section
      className={'rider' + (isOpen ? ' rider__detailOpen' : '')}
      onClick={toggle}
    >
      <h3>
        {firstname} {lastname}
      </h3>
      <small className='ttu'>{role}</small>
      <Detail
        {...rider}
        aside={stand}
        isOpen={isOpen}
        toggle={toggle}
        details={riderDetails}
        isFixed
      />
    </section>
  )
}
